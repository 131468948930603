import * as THREE from 'three';
import { notification } from 'antd';
import { IS_PROD, S3_IMAGES_URL } from './constants';
import { ArgsProps } from 'antd/es/notification';

export const openNewTab = (url: string) => {
  if (window) {
    window.open(url);
  }
};

export const searchParentRef = (mesh, searchWord) => {
  if (mesh) {
    if (mesh.name.includes(searchWord)) {
      return mesh;
    }
    return searchParentRef(mesh.parent, searchWord);
  }
  return undefined;
};

export function makeId(length) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
// return a position infront of camera by a dist: distance
export function getCameraRelativePosition(camera, dist = 3) {
  const dir = new THREE.Vector3();
  camera.getWorldDirection(dir);

  dir.normalize();
  dir.multiplyScalar(dist);

  const position = new THREE.Vector3();
  position.addVectors(dir, camera.position);
  //position.y = 0;

  return position;
}

export const getRotationRelativeToCamera = (modelPosition, cameraPosition) => {
  const lookAtVector = new THREE.Vector3().subVectors(
    cameraPosition,
    modelPosition
  );

  lookAtVector.y = 0;

  const angle = Math.atan2(lookAtVector.x, lookAtVector.z);

  const rotation = new THREE.Euler(0, angle, 0);
  return rotation;
};

export const openNotification = (
  message: string,
  description: string,
  placement?: ArgsProps['placement'],
  duration?: number,
  type?: string
) => {
  notification[type || 'info']({
    message,
    description,
    duration: duration || 2.0,
    placement: placement || 'top',
  });
};

export const getObjectId = (name: string, selectableAssets) => {
  if (!selectableAssets.length) {
    return '1';
  }

  let i = 0;
  let counter = 1;
  const end = selectableAssets.length - 1;

  while (i <= end) {
    const elName = selectableAssets[i].props.asset.name;
    if (!elName) {
      continue;
    }
    const splittedName = elName.split('-');
    splittedName.splice(-1, 1);
    const nameWithoutCounter = splittedName.join('-');
    if (nameWithoutCounter === name) {
      counter++;
    }
    i++;
  }

  return counter.toString();
};

export function trackUmamiEvent(name) {
  if (IS_PROD) {
    window.umami.track(name);
  }
}

export function isValidURL(string: string): boolean {
  var res = string?.includes('https://');
  return res;
}

export const uploadFileToS3 = async (
  file: File,
  creator: string,
  folder: string
) => {
  const data = new FormData();
  data.set('file', file);
  data.set('fileName', file.name);
  data.set('email', creator);
  data.set('fileType', folder);

  const res = await fetch('/api/upload-file-s3', {
    method: 'POST',
    body: data,
  }).then((res) => res.json());

  if (!res?.status) throw new Error('Error when uploading image');

  return `${S3_IMAGES_URL}/${creator}/${folder}/${file.name}`;
};
