import { useEffect, useState } from 'react';
import { Checkbox, Flex, Modal } from 'antd';
import DefaultButton from '@src/components/ui-kit/buttons/DefaultButton';
import { MARKETPLACE_CDN_URL } from 'util/constants';
import styles from './unity-intructions-modal.module.css';

interface Props {
  isOpen: boolean;
  setIsOpen: Function;
}

export const UnityInstructionsModalContent = (
  <>
    <h3 className={styles.boldMainTitle}>
      OZONE Unity Web Builder: Export & Unity Integration Guide
    </h3>
    <p>
      Follow these steps to export your{' '}
      <span className={styles.bold}>OZONE Web World</span> and import it into
      <span className={styles.bold}> Unity </span>
      as a <span className={styles.bold}>.unitypackage.</span>
    </p>
    <h3 className={styles.boldTitle}>One time setup:</h3>
    <ul className={styles.list}>
      <li>
        1. <span className={styles.bold}>Download Unity Hub</span> and install{' '}
        <span className={styles.bold}>Unity 2021.3 LTS</span> (recommended for
        compatibility). (
        <a
          target='_blank'
          className={styles.link}
          href='https://unity.com/releases/editor/whats-new/2021.3.48#notes'
        >
          Download ↗
        </a>
        )
      </li>
      <li>
        2.{' '}
        <span className={styles.bold}>
          Download the OZONE Unity Web Builder package
        </span>{' '}
        for <span className={styles.bold}>GLTF/GLB support</span>.{' '}
        <span>
          <a
            href={`${MARKETPLACE_CDN_URL}unity-dependencies/OzoneSetup.unitypackage`}
            target='_self'
            className={styles.link}
          >
            (Download)
          </a>
        </span>
      </li>
      <li>
        3.{' '}
        <span className={styles.bold}>Download the OZONE World Template</span>{' '}
        for Unity{' '}
        <span>
          <a
            href={'https://upm.zepeto.run/setup/package'}
            target='_self'
            className={styles.link}
          >
            (Download)
          </a>
        </span>
      </li>
    </ul>
    <h3 className={styles.boldTitle}>Exporting from OZONE Web Builder:</h3>
    <ol className={styles.list}>
      <li>
        1. Open your project in{' '}
        <span className={styles.bold}>OZONE Web Builder</span>.
      </li>
      <li>
        2. Click <span className={styles.bold}>‘Export As’</span> and choose the{' '}
        <span className={styles.bold}>‘Unity Package (.unitypackage)’</span>{' '}
        option.
      </li>
      <li>
        3. The system will generate a packaged file including:
        <li className={styles.bold}>· GLTF/GLB world assets</li>
        <li className={styles.bold}>· Metadata and prefab structure</li>
        <li className={styles.bold}>· Materials and textures</li>
      </li>
      <li>4. Download the exported .unitypackage file.</li>
    </ol>
    <h3 className={styles.boldTitle}>Importing into Unity:</h3>
    <ol className={styles.list}>
      <li>
        1. <span className={styles.bold}>Open Unity</span> and create a new 3D
        project.
      </li>
      <li>
        2. <span className={styles.bold}>Install the OZONE Setup package</span>{' '}
        (for compatibility and scene processing).
      </li>
      <li>
        3. <span className={styles.bold}>Drag & Drop</span> the .unitypackage
        file into Unity.
      </li>
      <li>
        4. In the <span className={styles.bold}>Import Package</span> window,
        click <span className={styles.bold}>‘Import All’</span>.
      </li>
      <li>
        5. <span className={styles.bold}>Verify the scene</span> and assets in
        the Unity hierarchy.
      </li>
      <li>
        6. Adjust lighting, physics, or additional post-processing as needed.
      </li>
    </ol>
    <blockquote className={styles.callout}>
      <h3 className={styles.boldTitle}>That's it! 🚀</h3>
      <span>
        Your OZONE world is now inside Unity and ready for further development!
      </span>
      <p className={styles.bold}>
        📘 Full video tutorial and step-by-step guide coming soon.
      </p>
      <h3 className={styles.boldTitle}>Additional Resources:</h3>
    </blockquote>
    <ul className={styles.list}>
      <li>
        <a
          href='https://docs.ozonemetaverse.io/studio'
          target='_blank'
          className={styles.link}
        >
          · OZONE Web Builder Documentation
        </a>
      </li>
      <li>
        <a
          href='https://docs.ozonemetaverse.io/faq'
          target='_blank'
          className={styles.link}
        >
          · FAQs and Support
        </a>
      </li>
    </ul>
  </>
);

export default function UnityInstructionModal({ isOpen, setIsOpen }: Props) {
  const [show, setIsShow] = useState(true);
  const [isNotShowChecked, setIsNotShowChecked] = useState(false);

  function closeModal() {
    if (isNotShowChecked) {
      window.localStorage.setItem('not_show_unity_instructions', 'true');
    }
    setIsOpen(false);
  }

  useEffect(() => {
    if (window) {
      const notShow = window.localStorage.getItem(
        'not_show_unity_instructions'
      );
      if (!notShow) {
        return;
      }
      if (notShow === 'true') {
        setIsShow(false);
      }
    }
  }, []);

  if (!show) {
    return <></>;
  }

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={false}
      destroyOnClose
      className='instruction-modal'
      width={670}
    >
      <div className={styles.instructionsWrapper}>
        {UnityInstructionsModalContent}
        <Flex align='center' justify='end' gap={10} style={{ marginTop: 40 }}>
          <Checkbox onChange={(e) => setIsNotShowChecked(e.target.checked)}>
            Don't show me this again
          </Checkbox>
          <DefaultButton type='primary' onClick={closeModal}>
            <span style={{ color: '#fff' }}>Close</span>
          </DefaultButton>
        </Flex>
      </div>
    </Modal>
  );
}
