import { NavProfile } from '@ozoneuniverse/ui-components/build-pkg/src/components/nav-bar/nav-profile/NavProfile';
import { Button, Col, Tooltip } from 'antd';
import Link from 'next/link';
import LoginModal from '../auth/Login';
import { MenuDrawer } from './menu-drawer/MenuDrawer';
import UnityInstructionModal from '../scene/editor/unity/UnityInstructionsModal';
import { homeButton } from './icons';
import { trackUmamiEvent } from 'util/helpers';
import { useRouter } from 'next/router';
import { signOut } from 'next-auth/react';
import { SIGN_IN_URL } from 'util/constants';
import { useGetUserPublicQuery } from '../generated/graphql';
import { memo } from 'react';

interface Props {
  inEditor: boolean;
  userEmail: string;
  isLoginOpen?: boolean;
  setIsLoginOpen?: Function;
  setIsTourOpen?: Function;
  styles: any;
  isSaveUnityModalOpen: boolean;
  setIsSaveUnityModalOpen: Function;
  isSignIn: boolean;
}

const signIn = () => {
  trackUmamiEvent('User: Login');
  window.location.assign(SIGN_IN_URL);
};

const ProfileButtons: React.FC<Props> = ({
  inEditor,
  styles,
  setIsLoginOpen,
  isLoginOpen,
  setIsTourOpen = () => {},
  isSaveUnityModalOpen,
  setIsSaveUnityModalOpen,
  userEmail,
  isSignIn,
}) => {
  const router = useRouter();

  const { data: userData } = useGetUserPublicQuery({
    variables: {
      where: {
        email: userEmail,
      },
    },
  });

  function runTour() {
    setIsTourOpen(true);
  }
  return (
    <>
      {isSignIn && !inEditor && (
        <Col className={styles.beCreatorButton} style={{ marginRight: 20 }}>
          <Link href='/user/studio' className={styles.exploreLink}>
            Studio
          </Link>
        </Col>
      )}
      {isSignIn && (
        <div className={styles.userProfileWrapper}>
          <div className={styles.userProfile}>
            <NavProfile
              userData={userData?.getUserPublic.user ?? {}}
              signOut={signOut}
              isCreator={false}
              type='studio'
              runTour={runTour}
            />
          </div>
        </div>
      )}
      {!isSignIn && inEditor && (
        <Col className={styles.authButtons}>
          <LoginModal setIsOpen={setIsLoginOpen} isOpen={isLoginOpen} />
        </Col>
      )}
      {isSignIn && inEditor && (
        <Tooltip title='My Worlds' placement='bottomLeft'>
          <Button
            className={styles.homeButton}
            onClick={() => router.push('/user/studio')}
            data-cy="my-worlds-button"
          >
            {homeButton}
          </Button>
        </Tooltip>
      )}
      {!isSignIn && !inEditor && (
        <Col className={styles.authButtons}>
          <Button 
            className={styles.signInButton} 
            onClick={signIn} 
            data-cy="main-sign-in-button"
          >
            Sign in / Sign up
          </Button>
        </Col>
      )}
      <Col>
        <div className={styles.menuDrawer}>
          {!isSignIn && !inEditor ? (
            <Button 
              className={styles.signInButton} 
              onClick={signIn} 
              data-cy="mobile-sign-in-button"
            >
              Login
            </Button>
          ) : (
            <MenuDrawer isSignIn={isSignIn} />
          )}
        </div>
      </Col>
      <UnityInstructionModal
        isOpen={isSaveUnityModalOpen}
        setIsOpen={setIsSaveUnityModalOpen}
      />
    </>
  );
};

export default memo(ProfileButtons);
