import React from 'react';
import { Modal } from 'antd';
import { MARKETPLACE_CDN_URL } from 'util/constants';
import styles from './zepeto-instructions-modal.module.css';

interface Props {
  setIsOpen: Function;
  isOpen: boolean;
}

export const ZepetoInstructionsModalContent = (
  <>
    <h3>ZEPETO Web Builder World export and Unity integration guide</h3>
    <p>
      Please follow the steps required for importing the ZEPETO Web World into
      Unity.
    </p>
    <h3>One time setup:</h3>
    <ul className={styles.list}>
      <li>
        Download Unity Hub and Unity 2020.3.9f1 version. (
        <a
          target='_blank'
          className={styles.link}
          href='https://unity3d.com/get-unity/download/archive'
        >
          Download ↗
        </a>
        )
      </li>
      <li>
        Download the ZEPETO Web Builder package for Unity-GLTF Support{' '}
        <span>
          <a
            href={`${MARKETPLACE_CDN_URL}unity-dependencies/OzoneSetup.unitypackage`}
            target='_self'
            className={styles.link}
          >
            (Download)
          </a>
        </span>
      </li>

      <li>
        Download the ZEPETO World Template{' '}
        <span>
          <a
            href={'https://upm.zepeto.run/setup/package'}
            target='_self'
            className={styles.link}
          >
            (Download)
          </a>
        </span>
      </li>
    </ul>

    <h3>Import the ZEPETO Web World into Unity:</h3>
    <ol className={styles.list}>
      <li>
        Click 'Export as' and choose the 'ZepetoWeb' option it will download the
        World assets package.
      </li>
      <li>Install Ozone Setup package.</li>
      <li>
        Drag and drop scene to the Unity and extract using Ozone Setup package.
      </li>
      <li>That's it! 🚀</li>
    </ol>

    <blockquote className={styles.callout}>
      <h3 className={styles.title}>
        <span className={styles.calloutIcon}>📘</span>
        <p className={styles.calloutText}>
          Full video and step-by-step guide on how to import the ZEPETO Web
          World into Unity
        </p>
      </h3>
      <p>
        <span>
          <a
            className={`${styles.calloutLink} ${styles.link}`}
            target='_blank'
            href='https://docs.ozonemetaverse.io/zepeto/import_scene_unity'
          >
            ZEPETO Web Builder guide ↗
          </a>
        </span>
      </p>
    </blockquote>
    <h3>Additional Resources:</h3>
    <ul className={styles.list}>
      <li>
        <a
          href='https://docs.zepeto.me/studio/reference/installation_and_settings'
          target='_blank'
          className={styles.link}
        >
          ZEPETO Unity setup guide ↗
        </a>
      </li>
    </ul>
  </>
);

export const ZepetoInstructionsModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
      }}
      footer={null}
    >
      {ZepetoInstructionsModalContent}
    </Modal>
  );
};

export default ZepetoInstructionsModal;
