import React, { memo, useContext } from 'react';
import { BrandingContext } from 'pages/_app';
import { S3_IMAGES_URL } from 'util/constants';
import StudioLogo from '@ozoneuniverse/ui-components/build-pkg/src/components/logo/StudioLogo';
import NavigationLogoSection from './zepeto/ZepetoLogo';
import NavigationUnityLogoSection from './unity-web-builder/UnityLogo';
import { CDN_MARKETPLACE } from '@src/components/scene/assets/baseUrl';
import { Flex } from 'antd';
import Image from 'next/image';

const Logo = ({ styles, inEditor, externalLogoURL, isDark }) => {
  const branding = useContext(BrandingContext);

  if (branding == 'ZEPETO') {
    return <NavigationLogoSection styles={styles} />;
  }

  if (inEditor && branding == 'UNITY') {
    return <NavigationUnityLogoSection styles={styles} />;
  }

  const darkThemeLogoUrl = `${CDN_MARKETPLACE}/assets/logos/logo-for-dark-theme.png`;

  if (isDark) {
    return (
      <Flex align='center'>
        <Image src={darkThemeLogoUrl} alt='Logo' width={130} height={35} />
      </Flex>
    );
  }

  return (
    <StudioLogo
      s3ImagesUrl={S3_IMAGES_URL as string}
      inEditor={inEditor}
      externalLogoURL={externalLogoURL}
    />
  );
};

export default memo(Logo);
